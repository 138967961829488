<template>
<el-upload
  :style="{'--imgWidth': imgWidth + 'px', '--imgHeight': imgHeight + 'px'}"
  :disabled="fileDisabled ? fileDisabled : false"
  :class="{ 'img-uploader': imgShow }"
  action="/common/qiniu/uploadFile"
  list-type="picture-card"
  :data="fileParams"
  :show-file-list="false"
  :on-success="handleImgSuccess"
  :before-upload="beforeImgUpload"
  accept=".jpg, .jpeg, .png, .gif"
  :multiple="false"
>
  <img v-if="img" :src="img" class="img" style="object-fit: contain;width:100%;" />
  <i v-else class="el-icon-plus img-uploader-icon" />
  <div class="el-upload__tip" slot="tip">
    图片小于5M，支持jpg、jpeg、png、gif格式
  </div>

  <el-dialog
    top="2vh"
    :width="2 * cropBoxWidth + 200 + 'px'"
    title="图片裁剪"
    :visible.sync="cropperShow"
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="false">
    <div class="cropper-body">
      <ul class="cropper-steps">
        <li class="cropper-step">
          <span class="cropper-image_step-title">选择图片</span>
        </li>
        <li class="cropper-step current">
          <span>编辑图片（先右边选择尺寸；后左边移动裁剪框或图片裁剪，支持滚轮缩放）</span>
        </li>
      </ul>
      <div>
        <el-row>
          <el-col :span="12">
            <h4 style="margin-left: 40px;">裁剪图片</h4>
            <div :style="{'width': cropBoxWidth + 'px', 'height': cropBoxHeight + 'px', 'margin-left': '40px'}">
              <vueCropper
                ref="cropper"
                :img="option.img"
                :outputType="option.outputType"
                :info="option.info"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :maxImgSize="option.maxImgSize"
                :fixedBox="option.fixedBox"
                :canScale="option.canScale"
                :canMove="option.canMove"
                :mode="option.mode"
                :fixed="option.fixed"
                :high="option.high"
                :full="option.full"
                @realTime="realTime"
              ></vueCropper>
            </div>
          </el-col>
          <el-col :span="12">
            <h4>预览图片</h4>
            <ul class="img_crop_preview_list">
              <li :class="index === isFilter ? 'img_crop_preview_item current' : 'img_crop_preview_item'" v-for="(item, index) in cropConfList" :key="index" @click="handleSelect(item, index)">
                <div :style="item.previewStyle">
                  <div :style="previews.div">
                    <img :src="previews.url" :style="previews.img">
                  </div>
                </div>
                <div style="margin-left:20px;">
                  <p style="font-weight:bold;">{{item.title}}</p>
                  <p>{{item.desc}}</p>
                </div>
              </li>
            </ul>
          </el-col>
        </el-row>
        
      </div>
      <div class="cropperBtn">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="handleCropper">确定</el-button>
      </div>
    </div>
  </el-dialog>
</el-upload>
</template>
<script>
import { VueCropper }  from 'vue-cropper'
export default {
  name: 'MyVueCropper',
  props: ['fileData', 'fileRule', 'fileDisabled', 'fileShow'],
  components: {
    VueCropper
  },
  data() {
    return {
      img: this.fileData,
      imgShow: true,
      imgWidth: 60,
      imgHeight: 60,
      newFile: {},
      fileParams: {
        fileRule: this.fileRule,
      },
      cropperShow: false,
      cropBoxWidth: 800,
      cropBoxHeight: 700,
      option: {
        img: '',
        autoCrop: true, // 是否默认生成截图框
        outputType: 'jpeg',
        maxImgSize: 3000, // 限制图片最大宽度和高度
        autoCropWidth: 800, // 默认生成截图框宽度
        autoCropHeight: 450, // 默认生成截图框高度
        canMove: true, // 上传图片是否可以移动
        fixedBox: true, // 固定截图框大小 不允许改变
        canScale: true, // 图片是否允许滚轮缩放
        mode: '100% auto', // 图片默认渲染方式
        info: false, // 裁剪框的大小信息
        fixed: false, // 是否开启截图框宽高固定比例
        full: true, // 是否输出原图比例的截图
        high: false, // 是否按照设备的dpr 输出等比例图片
      },
      previewStyle: {},
      previews: {},
      cropConfList: [],
      isFilter: 0,

      imgData:[
        {
          width:1920,
          title:"全屏轮播图（1.78:1）",
          desc:"首页全屏轮播图，建议最小尺寸1920x1080",
          height:1080
        },
        {
          width:1920,
          title:"功能轮播图（2.56:1）",
          desc:"功能轮播图，建议最小尺寸1920x750",
          height:750
        },
        {
          width:690,
          title:"新闻图文封面（1.78:1）",
          desc:"图文封面，建议最小尺寸690x388",
          height:388
        },
        {
          width:690,
          title:"1:1图（1:1）",
          desc:"正方形封面，建议最小尺寸690x690",
          height:690
        }
      ]
    }
  },
  created() {
    this.getCropperConf();
    if (this.fileShow === false) {
      this.imgShow = false;
    }
  },
  watch: {
    fileData(newVal) {
      this.img = newVal;
    },
    fileDisabled(newVal) {
      this.fileDisabled = newVal;
    }
  },
  methods: {
    getCropperConf() {
      this.cropConfList = this.imgData
      this.cropConfList.forEach((item, index) => {
        if (index === 0) {
          this.option.autoCropHeight = Math.ceil((item.height / item.width) * this.option.autoCropWidth);
        }
        if (item.width > this.cropBoxWidth) {
          const tmp_w = this.option.autoCropWidth;
          const tmp_h = Math.ceil((item.height / item.width) * this.option.autoCropWidth);
          item.previewStyle = {
            width: tmp_w + "px",
            height: tmp_h + "px",
            overflow: "hidden",
            margin: "0",
            zoom: (150 / tmp_h)
          };
        } else {
          const tmp_w = item.width;
          const tmp_h = item.height;
          item.previewStyle = {
            width: tmp_w + "px",
            height: tmp_h + "px",
            overflow: "hidden",
            margin: "0",
            zoom: (150 / tmp_h)        
          };
        }
      });
    },
    handleImgSuccess(res) {
      if (res.success == true) {
        this.img = res.data.all_path_url;
        if (this.imgShow) {
          this.img = res.data.all_path_url;
        }
        this.$emit('crop-success', res.data);
      } else {
        this.$message.warning("图片上传失败！");
      }
    },
    beforeImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过5MB！');
        return false;
      }
      if (file.type != 'image/gif') {
        this.newFile = file;
        this.cropperShow = true;
        if (window.FileReader) {
          let fr = new FileReader();
          fr.onload = e => {
            this.option.img = e.target.result;
          };
          fr.readAsDataURL(this.newFile);
        }
        return false;
      }
    },
    handleCropper() {
      this.$refs.cropper.getCropBlob((cropData) => {
        let formData = new FormData();
        formData.append('fileRule', this.fileRule);
        formData.append('file', cropData, this.newFile.name);
        
        this.$http.post('/common/qiniu/uploadFile', formData, {contentType: false, processData: false}).then(({ code, data }) => {
          if (code == 1) {
            this.resetCropper();
             this.img = data.all_path_url;
            if (this.imgShow) {
              this.img = data.all_path_url;
            }
            this.$emit('crop-success', data); // 发送事件crop-success到父组件
          }
        }).catch(() => {});
      });
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data;
    },
    handleCancel() {
      this.resetCropper();
    },
    resetCropper() {
      this.cropperShow = false;
      this.option.img = '';
    },
    handleSelect(item, index) {
      this.isFilter = index;
      if (item.width > this.cropBoxWidth) {
        this.option.autoCropWidth = this.cropBoxWidth;
        this.option.autoCropHeight = Math.ceil((item.height / item.width) * this.option.autoCropWidth);
        this.option.full = true;
      } else {
        this.option.autoCropWidth = item.width;
        this.option.autoCropHeight = item.height;
        this.option.full = false;
      }
    }
  }
}
</script>
<style>
.img-uploader .el-upload--picture-card {
  width: var(--imgWidth);
  height: var(--imgHeight);
  line-height: var(--imgHeight);
}
.img-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.img-uploader .el-upload:hover {
  border-color: #409EFF;
}
.img-uploader .img-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: var(--imgWidth);
  height: var(--imgHeight);
  line-height: var(--imgHeight);
  text-align: center;
}
.img-uploader .img {
  width: var(--imgWidth);
  height: var(--imgHeight);
  display: block;
}
.cropperBtn{
  display: flex;
  justify-content: flex-end;
}
.cropper-steps {
  color: #b1b2b3;
  text-align: center;
  counter-reset: step;
}
.cropper-step {
  margin-bottom: 10px;
  vertical-align: top;
  display: inline-block;
  position: relative;
}
.cropper-step:before {
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  margin-left: 4px;
  counter-increment: step;
  content: counter(step);
  margin: -0.2em 6px 0 0;
  border: 1px solid #b1b2b3;
  border-radius: 50%;
}
.cropper-step:after {
  margin: 9px 16px;
  vertical-align: top;
  border-bottom: 1px solid #b1b2b3;
  content: "";
  display: inline-block;
  width: 30px;
}
.cropper-step:last-child:after {
  display: none;
}
.cropper-step.current {
  color: red;
}
.cropper-step.current:before {
  border-color: red;
}
.img-uploader img, .cropper-body img {
  max-width: inherit;
}

/*裁剪预览图片*/
.img_crop_preview_item:hover {
  background: #f7f7f7;
  cursor: pointer
}
.img_crop_preview_item{
  margin-bottom: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.img_crop_preview_item.current{
  position: relative;
}
.img_crop_preview_item.current:before{
  content: " ";
  display: block;
  width: 4px;
  height: 100%;
  background-color: red;
  position: absolute;
  top: 0;
  left: -1px;
}
</style>
